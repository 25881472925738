import React from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import bg from "../images/background-1.jpg";
import scientist from "../images/scientist-1.jpg";
import scientist2 from "../images/scientist-2.jpg";
import SEO from "../components/seo";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: "#333",
  },
}));

export default function LabNotes() {
  const classes = useStyles();
  return (
    <>
      <SEO
        title="Lab Notes"
        description="Take notes online and on the mobile app"
      />

      <Container maxWidth="lg" style={{ paddingBottom: "50px" }}>
        <Grid
          container
          spacing={3}
          style={{ marginTop: "50px", backgroundColor: "#fff" }}
        >
          <Grid item md style={{ backgroundColor: "#fff" }}>
            <Typography component="div">
              <Box
                textAlign="left"
                m={1}
                component="h2"
                fontFamily="DM Serif Text"
                fontWeight="700"
                color="#3d3d3d"
                fontSize="h3.fontSize"
              >
                Pay 75% Less for More Features and a Better Experience
              </Box>
            </Typography>
            <img
              src={scientist}
              alt="scientists in laboratory"
              style={{ maxWidth: "90%" }}
            />
          </Grid>
          <Grid item md>
            <Typography component="div">
              <Box color="#3d3d3d" marginTop="50px" textAlign="left">
                ✓ Get free, local, and on-site support in the United States
                instead of paying thousands for remote support.
                <Box />
              </Box>
              <Box color="#3d3d3d" marginTop="50px" textAlign="left">
                ✓ Dedicated mobile apps work offline and sync online, vs Website
                interface online-limited with others.
              </Box>
              <Box color="#3d3d3d" marginTop="50px" textAlign="left">
                ✓ Integrated analytics features makes tracking task data and
                monitoring progress easy.
              </Box>
              <Box color="#3d3d3d" marginTop="50px" textAlign="left">
                ✓ Schedule tasks and create actionable protocols. Get
                notifications on your phone or tablet.
              </Box>
              <Box color="#3d3d3d" marginTop="50px" textAlign="left">
                ✓ Send requests for document review and approval.
              </Box>
              <Box color="#3d3d3d" marginTop="50px" textAlign="left">
                ✓ Speed up FDA applications with an all-in-one solution for FDA
                electronic records compliance.
              </Box>
              <Box color="#3d3d3d" marginTop="50px" textAlign="left">
                ✓ Modern and powerful API integrates with your laboratory’s
                current systems.
              </Box>
              <Box color="#3d3d3d" marginTop="50px" textAlign="left">
                ✓ Flexible data storage options include in-house data storage,
                rather than the third-party ones offered by others.
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container
        maxWidth="lg"
        style={{ paddingTop: "30px", paddingBottom: "50px" }}
      >
        <Grid
          container
          spacing={3}
          style={{ marginTop: "30px", backgroundColor: "#fff" }}
        >
          <Grid item md>
            <Typography component="div">
              <Box
                textAlign="left"
                m={1}
                component="h2"
                fontFamily="DM Serif Text"
                fontWeight="700"
                color="#3d3d3d"
                fontSize="h3.fontSize"
              >
                LabLog is Easy for Everyone to Use
              </Box>
              <Box color="#3d3d3d" marginTop="50px" textAlign="left">
                Find the tools you need without sacrificing usability. We
                developed the perfect amount of features to make your job
                easier, but not so many to add confusion.
              </Box>
            </Typography>
          </Grid>
          <Grid item md style={{ backgroundColor: "#fff" }}>
            <img
              src={scientist2}
              alt="two scientists taking notes in the lab"
              style={{ maxWidth: "90%" }}
            />
          </Grid>
        </Grid>
      </Container>
      <div
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Container
          style={{
            paddingTop: "50px",
            paddingBottom: "30px",
            width: "100%",
          }}
        >
          <Grid container spacing={3}>
            <Grid item md>
              <Typography component="div">
                <Box
                  textAlign="left"
                  m={1}
                  component="h2"
                  fontFamily="DM Serif Text"
                  fontWeight="700"
                  color="#3d3d3d"
                  fontSize="h3.fontSize"
                >
                  Your All-in-One Solution
                </Box>
                <Box color="#3d3d3d" marginTop="10px" textAlign="left">
                  The LabLog electronic lab notebook app helps researchers to
                  create tasks, collect data, monitor progress, remain FDA
                  compliant, and more.
                </Box>
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Box color="#3d3d3d" marginTop="80px" textAlign="left">
                <i>
                  "We chose LabLog because I had heard about the company from
                  our peers, and they were all raving about the great features.
                  We were not disappointed at all!"
                  <br />
                  <br />- Adam Azulai, National Institutes of Health
                </i>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "20px" }}>
            <Grid item md={4}>
              <Typography component="div">
                <Box
                  marginBottom="0"
                  textAlign="left"
                  component="h5"
                  fontFamily="DM Serif Text"
                  fontWeight="600"
                  color="#3d3d3d"
                  fontSize="h5.fontSize"
                >
                  ➢ Local Support
                </Box>
                <Box color="#3d3d3d" component="p" textAlign="left">
                  Because we’re based in Maryland, we provide local support at
                  your location in the United States.
                </Box>
                <Box
                  marginBottom="0"
                  textAlign="left"
                  component="h5"
                  fontFamily="DM Serif Text"
                  fontWeight="600"
                  color="#3d3d3d"
                  fontSize="h5.fontSize"
                >
                  ➢ Data Storage
                </Box>
                <Box color="#3d3d3d" component="p" textAlign="left">
                  Flexible and secure data storage options include both
                  on-premise and cloud options.
                </Box>
                <Box
                  marginBottom="0"
                  textAlign="left"
                  component="h5"
                  fontFamily="DM Serif Text"
                  fontWeight="600"
                  color="#3d3d3d"
                  fontSize="h5.fontSize"
                >
                  ➢ Analytics
                </Box>
                <Box color="#3d3d3d" component="p" textAlign="left">
                  Gain meaningful insights from data recorded with the best
                  online lab notebook app.
                </Box>
                <Box
                  marginBottom="0"
                  textAlign="left"
                  component="h5"
                  fontFamily="DM Serif Text"
                  fontWeight="600"
                  color="#3d3d3d"
                  fontSize="h5.fontSize"
                >
                  ➢ FDA Compliance
                </Box>
                <Box color="#3d3d3d" component="p" textAlign="left">
                  Stay compliant while saving time and money with electronic
                  records.
                </Box>
              </Typography>
            </Grid>
            <Grid item md>
              <img
                alt="scientist"
                src={scientist}
                style={{ maxWidth: "95%", alignSelf: "right" }}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
